import React from "react";
import styled from "styled-components";
import { PageProps } from "gatsby";
import Layout from "../layout";
import SiteHead from "../components/SiteHead";
import { Wrap } from "../components/ui";

const Container = styled.div`

`;

const Search: React.FC<PageProps> = ({ location }) => {

  return (
    <Layout location={location} langCode="en">
      <SiteHead title="Search Results" pathname={location.pathname} />
      <Container>
        <Wrap>
          <div className="gcse-searchresults" />
        </Wrap>
      </Container>
    </Layout>
  );
};

export default Search;
